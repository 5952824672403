import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'

import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import moment from 'moment'

import "@/assets/scss/app.scss";



import VueFormulate from '@braid/vue-formulate'
import mixin from '@/service/mixin'
import VueSwal from 'vue-swal'
 



Vue.prototype.moment = moment

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(VueSwal)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueFormulate)

Vue.component('apexchart', VueApexCharts)

Vue.mixin({
  methods: mixin
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
