import axios from 'axios'
//import authHeader from '../auth-header'
import ConfigService from "../config"

const API_URL = ConfigService.base_url;

class AuthServiceApi {

    login(email,password) {
     
        return axios
          .post(API_URL + 'admin/login', {
            email: email,
            password: password
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      passwordReset(email) {
     
        return axios
          .post(API_URL + 'passwordreset', {
            email: email,
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
}

export default new AuthServiceApi()