import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (window.localStorage.getItem("token")) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
             
                store.dispatch('auth/logOut')
               
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/brands',
        name: 'Brands',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/brands/brands')
    },
    {
        path: '/categories',
        name: 'Categories',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/categories/categories')
    },
    {
        path: '/user',
        name: 'Users',
        component: () => import('../views/pages/users/main'),
        redirect: '/user/all',
        meta: {
            authRequired: true,
        },
       
        children: [
            {
              name: 'All Users',
              path: 'all',
              component: () => import('../views/pages/users/users'),
            },
            {
                name: 'User',
                path: ':id',
                component: () => import('../views/pages/users/user'),
                redirect: ':id/default',
                children: [
                    {
                        name: 'default',
                        path: 'default',
                        component: () => import('../views/pages/users/sub-views/default'),
                    },
                    {
                        name: 'User Edit',
                        path: 'edit',
                        component: () => import('../views/pages/users/sub-views/edit'),
                    },
                    {
                        name: 'disable',
                        path: 'disable',
                        props: true,
                        component: () => import('../views/pages/users/sub-views/disable'),
                    },
                    {
                        name: 'wallet',
                        path: 'wallet',
                        component: () => import('../views/pages/users/sub-views/wallet'),
                    },
                    {
                        name: 'user-notification',
                        path: 'user-notification',
                        component: () => import('../views/pages/users/sub-views/notification'),
                    }
                ]
            },
          ]
    },
   
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile')
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/global-setting')
    },
    {
        path: '/ecommerce/products',
        name: 'products',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/products')
    },
    {
        path: '/support',
        name: 'support',
        meta: { authRequired: true },
        component: () => import('../views/pages/support/support')
    },
    {
        path: '/ecommerce/settings',
        name: 'ecommerce settings',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/settings')
    },
    {
        path: '/ecommerce/product-detail/:id',
        name: 'product detail',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/product-detail')
    },
    {
        path: '/ecommerce/orders/:tab?',
        name: 'Orders',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/orders')
    },
    {
        path: '/ecommerce/trades',
        name: 'All Trades',
        meta: { authRequired: true },
        component: () => import('../views/pages/trades/trades')
    },
    {
        path: '/ecommerce/trades-in-progress',
        name: 'Trades In Progress',
        meta: { authRequired: true },
        component: () => import('../views/pages/trades/tradesInProgress')
    },
    {
        path: '/ecommerce/trades-history',
        name: 'Trades History',
        meta: { authRequired: true },
        component: () => import('../views/pages/trades/tradesHistory')
    },
    {
        path: '/coupons',
        name: 'Coupons',
        meta: { authRequired: true },
        component: () => import('../views/pages/coupons/coupons')
    },
  
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/pages/coming-soon',
        name: 'Coming-soon',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/coming-soon')
    },
 
    {
        path: '/pages/faqs',
        name: 'Faqs',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/faqs')
    },

 
    {
        path: '/pages/error-500',
        name: 'Error-500',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-500')
    },
   
    {
        path: '/broadcast',
        name: 'Broadcast',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/notifications/broadcast')
    },
    {
        path: '/btopic',
        name: 'Btopic',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/notifications/btopic')
    },
 
    {
        path: '*',
        name: 'Error-404',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-404')
    },
]
