<template>
  <div :class="{ arabicStyle: arabic }" id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

import getLanaguge from "@/service/language";

export default {
  name: "app",
  data() {
    return {
      arabic: false,
    };
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  mounted() {
    if (getLanaguge() === "ar") {
      this.arabic = true;
      document.querySelector("html").style.direction = "rtl";
      document.querySelector("body").style.textAlign = "right";
    } else {
      this.arabic = false;
    }
  },
};
</script>
<style lang="scss">
.arabicStyle {
  @import "@/assets/scss/app-rtl.scss";
  .spinner-pos {
    margin-right: calc(45% - 160px);
  }
  .spinner-prod {
    margin-right: calc(45% - 160px);
  }
}
</style>
