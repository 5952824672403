import AuthServiceApi from "../../service/api/auth"
export const state = {
    token: sessionStorage.getItem('token'),
    user: sessionStorage.getItem('user'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.token = newValue.token
        state.user = newValue.user
        saveState('token', newValue.token)
        saveState('user', newValue.user)
    },
    RM_TOKEN(){
        state.token = null;
        state.user = null;
        removeState('token');
        removeState('user');
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        return AuthServiceApi.login(email, password).then((response) => {
             const data = response.data.success
            commit('SET_CURRENT_USER', data)
            return response
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('RM_TOKEN')
    },

   

  
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
function removeState(key) {
    window.localStorage.removeItem(key);
}
